port module Main exposing (Model, Msg(..), init, main, update, view)

import Browser
import Firestore
import Firestore.Config as Config
import Firestore.Decode as FSDecode
import Firestore.Types.Geopoint as Geopoint
import Firestore.Types.Reference as Reference
import Html exposing (Html, button, div, h1, h2, h3, img, input, p, text)
import Html.Attributes exposing (placeholder, src, value)
import Html.Events exposing (onClick, onInput)
import Json.Decode as Decode exposing (Decoder, decodeString, decodeValue, errorToString, float, int, list, nullable, string)
import Json.Decode.Pipeline exposing (hardcoded, optional, required)
import Json.Encode
import Result.Extra as ExResult


port signIn : () -> Cmd msg


port signInInfo : (Json.Encode.Value -> msg) -> Sub msg


port signInError : (Json.Encode.Value -> msg) -> Sub msg


port signOut : () -> Cmd msg


port saveMessage : Json.Encode.Value -> Cmd msg


port receiveMessages : (Json.Encode.Value -> msg) -> Sub msg



---- MODEL ----


type alias ErrorData =
    { code : Maybe String, message : Maybe String, credential : Maybe String }


type alias UserData =
    { token : String, email : String, uid : String }


type alias Model =
    { userData : Maybe UserData, error : ErrorData, inputContent : String, messages : List String }


init : ( Model, Cmd Msg )
init =
    ( { userData = Maybe.Nothing, error = emptyError, inputContent = "", messages = [] }, Cmd.none )



---- UPDATE ----


type Msg
    = LogIn
    | LogOut
    | LoggedInData (Result Decode.Error UserData)
    | LoggedInError (Result Decode.Error ErrorData)
    | SaveMessage
    | InputChanged String
    | MessagesReceived (Result Decode.Error (List String))


emptyError : ErrorData
emptyError =
    { code = Maybe.Nothing, credential = Maybe.Nothing, message = Maybe.Nothing }


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        LogIn ->
            ( model, signIn () )

        LogOut ->
            ( { model | userData = Maybe.Nothing, error = emptyError, messages = [] }, signOut () )

        LoggedInData result ->
            case result of
                Ok value ->
                    ( { model | userData = Just value }, Cmd.none )

                Err error ->
                    ( { model | error = messageToError <| errorToString error }, Cmd.none )

        LoggedInError result ->
            case result of
                Ok value ->
                    ( { model | error = value }, Cmd.none )

                Err error ->
                    ( { model | error = messageToError <| errorToString error }, Cmd.none )

        SaveMessage ->
            ( model, saveMessage <| messageEncoder model )

        InputChanged value ->
            ( { model | inputContent = value }, Cmd.none )

        MessagesReceived result ->
            case result of
                Ok value ->
                    ( { model | messages = value }, Cmd.none )

                Err error ->
                    ( { model | error = messageToError <| errorToString error }, Cmd.none )


messageEncoder : Model -> Json.Encode.Value
messageEncoder model =
    Json.Encode.object
        [ ( "content", Json.Encode.string model.inputContent )
        , ( "uid"
          , case model.userData of
                Just userData ->
                    Json.Encode.string userData.uid

                Maybe.Nothing ->
                    Json.Encode.null
          )
        ]


messageToError : String -> ErrorData
messageToError message =
    { code = Maybe.Nothing, credential = Maybe.Nothing, message = Just message }


errorPrinter : ErrorData -> String
errorPrinter errorData =
    Maybe.withDefault "" errorData.code ++ " " ++ Maybe.withDefault "" errorData.credential ++ " " ++ Maybe.withDefault "" errorData.message


userDataDecoder : Decoder UserData
userDataDecoder =
    Decode.succeed UserData
        |> required "token" string
        |> required "email" string
        |> required "uid" string


logInErrorDecoder : Decoder ErrorData
logInErrorDecoder =
    Decode.succeed ErrorData
        |> required "code" (nullable string)
        |> required "message" (nullable string)
        |> required "credential" (nullable string)


messageListDecoder : Decoder (List String)
messageListDecoder =
    Decode.succeed identity
        |> required "messages" (list string)



---- VIEW ----


view : Model -> Html Msg
view model =
    div []
        [ img [ src "/logo.svg" ] []
        , h1 [] [ text "Your Elm App is working!" ]
        , case model.userData of
            Just _ ->
                button [ onClick LogOut ] [ text "Logout from Google" ]

            Maybe.Nothing ->
                button [ onClick LogIn ] [ text "Login with Google" ]
        , h2 []
            [ text <|
                case model.userData of
                    Just data ->
                        data.email

                    Maybe.Nothing ->
                        ""
            ]
        , case model.userData of
            Just _ ->
                div []
                    [ input [ placeholder "Message to save", value model.inputContent, onInput InputChanged ] []
                    , button [ onClick SaveMessage ] [ text "Save new message" ]
                    ]

            Maybe.Nothing ->
                div [] []
        , div []
            [ h3 []
                [ text "Previous messages"
                , div [] <|
                    List.map
                        (\m -> p [] [ text m ])
                        model.messages
                ]
            ]
        , h2 [] [ text <| errorPrinter model.error ]
        ]



---- PROGRAM ----


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ signInInfo (decodeValue userDataDecoder >> LoggedInData)
        , signInError (decodeValue logInErrorDecoder >> LoggedInError)
        , receiveMessages (decodeValue messageListDecoder >> MessagesReceived)
        ]


main : Program () Model Msg
main =
    Browser.element
        { view = view
        , init = \_ -> init
        , update = update
        , subscriptions = subscriptions
        }
